import React from "react";

import CreditCardAdvantagesType from "../CreditCardAdvantagesType";
import OffSiteAdvantagesType from "../OffSiteAdvantagesType";
import Wrapper from "../shared/Wrapper";

type AdvantagesType = {
  isMobile?: boolean;
  type: "credit_card" | "offsite";
};

const Advantages: React.FC<AdvantagesType> = ({ isMobile, type }) => {
  const isCreditCardType = type === "credit_card";

  return (
    <Wrapper
      display={isMobile ? "grid" : "flex"}
      gridTemplateColumns={isMobile ? "auto auto" : "auto auto auto auto"}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      padding="0 27px"
      gap={isMobile ? "15px" : "35px"}
    >
      {isCreditCardType ? (
        <CreditCardAdvantagesType />
      ) : (
        <OffSiteAdvantagesType />
      )}
    </Wrapper>
  );
};

export default Advantages;
