import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { getPlans } from "../services";

import { ErrorResponseType, PlansResponseType } from "../types";

const usePlans = (type: string) => {
  const [data, setData] = useState<PlansResponseType[]>([]);
  const [error, setError] = useState<ErrorResponseType>(
    {} as ErrorResponseType
  );

  const { isLoading: isFetching } = useQuery({
    queryKey: ["plans"],
    queryFn: async () =>
      getPlans(type)
        .then((response) => setData(response))
        .catch((error: ErrorResponseType) => setError(error)),
  });

  return { data, error, isFetching };
};

export default usePlans;
