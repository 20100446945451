import axios, { AxiosError, AxiosResponse } from "axios";

import { ApiHelper, getSessionId } from "../utils";

import { HttpStatusCodes, KeyValue } from "../constants";

import env from "../env.json";

const { baseURL, mockMode } = env;
const {
  MOCK_MODE_KEY,
  CONTENT_TYPE_KEY,
  CONTENT_TYPE_VALUE,
  X_ONBOARDING_SESSION_ID_KEY,
} = KeyValue;

const statusValidator = ({ status }: AxiosResponse<any>): boolean => {
  return (
    status >= HttpStatusCodes.OK && status < HttpStatusCodes.MULTIPLE_CHOICES
  );
};

const headers = {
  [MOCK_MODE_KEY]: mockMode,
  [CONTENT_TYPE_KEY]: CONTENT_TYPE_VALUE,
  [X_ONBOARDING_SESSION_ID_KEY]: getSessionId(),
};

export const api = axios.create({
  baseURL,
  headers,
  timeout: 1000 * 10, // 10 seconds
  validateStatus: (status: any) =>
    statusValidator({ status } as AxiosResponse<any>),
});

const handleError = (error: AxiosError) => {
  return Promise.reject(ApiHelper.handleAxiosError(error));
};

api.interceptors.request.use(ApiHelper.interceptorRequest, handleError);
api.interceptors.response.use(ApiHelper.interceptorResponse, handleError);
