import React from "react";

import { useMediaQuery } from "../../hooks";

import Span from "../shared/Span";
import Wrapper from "../shared/Wrapper";

import { Colors, Configs } from "../../constants";

const CreditCardContent: React.FC = () => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      justifyContent={isMobile ? "center" : "start"}
      alignItems={isMobile ? "center" : "flex-start"}
      width={isMobile ? undefined : "499px"}
      margin={isMobile ? undefined : "82px 168px 52px 152px"}
      padding={isMobile ? "41px" : "16px"}
      flexWrap="wrap"
    >
      <Span color={Colors.MINE_SHAFT} fontSize={isMobile ? "16px" : "30px"}>
        Agora você pode integrar no <br />
        PagBank na sua loja da Shopify!
      </Span>

      <Span
        color={Colors.DOVE_GREY}
        fontSize={isMobile ? "12px" : "20px"}
        fontWeight="400"
        lineHeight={isMobile ? "17px" : "27.3px"}
        margin="24px 0px"
        textAlign={isMobile ? "center" : "start"}
      >
        Ofereça a melhor experiência de compra aos seus clientes com a
        tecnologia e segurança do PagBank.
      </Span>

      <Span
        color={Colors.DOVE_GREY}
        fontSize={isMobile ? "12px" : "20px"}
        fontWeight="500"
        margin="0 0 24px 0"
        lineHeight={isMobile ? "17px" : "27.3px"}
        textAlign={isMobile ? "center" : "start"}
      >
        Conecte o{" "}
        <Span
          color={Colors.DOVE_GREY}
          fontSize={isMobile ? "12px" : "20px"}
          fontWeight="700"
          margin="0 0 24px 0"
          lineHeight={isMobile ? "17px" : "27.3px"}
          textAlign={isMobile ? "center" : "start"}
        >
          Cartão de Crédito
        </Span>{" "}
        do PagBank
      </Span>

      <Span
        color={Colors.DOVE_GREY}
        fontSize={isMobile ? "12px" : "20px"}
        fontWeight="400"
        lineHeight={isMobile ? "17px" : "27.3px"}
        textAlign={isMobile ? "center" : "start"}
      >
        Selecione prazo de recebimento e taxa sobre as vendas que melhor
        funciona para sua loja:
      </Span>
    </Wrapper>
  );
};

export default CreditCardContent;
