import React from "react";

import Image from "../Image";
import Separator from "../Separator";
import Span from "../Span";
import Wrapper from "../Wrapper";

import { Colors } from "../../../constants";

type CardProps = {
  isMobile?: boolean;
  title: string;
  subTitle: string;
  icon: string;
};

const Card: React.FC<CardProps> = ({
  isMobile = false,
  title,
  subTitle,
  icon,
}) => {
  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      maxWidth={isMobile ? "145px;" : "230px"}
      height={isMobile ? "168px" : "202px"}
      padding="19.88px"
      backgroundColor={Colors.WHITE}
      borderRadius="5.68px"
      border={`1.42px ${Colors.MERCURY} solid`}
      alignItems="center"
      gap={isMobile ? "15px" : "19.88px"}
    >
      <Image
        src={icon}
        width={isMobile ? "36.364px" : "56.813px"}
        height={isMobile ? "36.364px" : "56.813px"}
      />
      <Wrapper
        display="flex"
        flexDirection="column"
        height={isMobile ? "25px" : "17px"}
        padding="8px"
      >
        <Span
          color={Colors.BLACK}
          fontSize={isMobile ? "11px" : "17.186px"}
          fontWeight="700"
          textAlign="center"
          uppercase
        >
          {title}
        </Span>
      </Wrapper>
      <Separator width="100%" border="1.42px #E7E7E7 solid" />
      <Span
        color={Colors.SCORPION}
        fontWeight="400"
        fontSize={isMobile ? "11px" : "17.044px"}
        textAlign="center"
      >
        {subTitle}
      </Span>
    </Wrapper>
  );
};

export default Card;
