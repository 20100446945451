import React from "react";

import { useMediaQuery } from "../../hooks";

import Card from "../shared/Card";

import {
  Cartao1X1SVG,
  CertificadoSVG,
  MoneySVG,
  SegurancaSVG,
} from "../../assets/images";

import { Configs } from "../../constants";

const CreditCardAdvantagesType: React.FC = () => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);

  return (
    <>
      <Card
        isMobile={isMobile}
        title="Segurança para seus clientes"
        subTitle="Suporte 24h"
        icon={SegurancaSVG}
      />
      <Card
        isMobile={isMobile}
        title="aceite as principais bandeiras"
        subTitle="Mais de 25 cartões nacionais e internacionais"
        icon={Cartao1X1SVG}
      />
      <Card
        isMobile={isMobile}
        title="CONFIANÇA  E CREDIBILIDADE"
        subTitle="Somos uma das principais empresas de pagamentos"
        icon={CertificadoSVG}
      />
      <Card
        isMobile={isMobile}
        title="VENDA MAIS"
        subTitle="Mais opções de pagamentos"
        icon={MoneySVG}
      />
    </>
  );
};

export default CreditCardAdvantagesType;
