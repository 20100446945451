import React from "react";

import Carousel from "nuka-carousel";

import { useMediaQuery } from "../../hooks";

import { PlansResponseType } from "../../types";

import Span from "../shared/Span";
import Wrapper from "../shared/Wrapper";
import CreditCardCarouselContent from "./CreditCardCarouselContent";
import OffSiteCarouselContent from "./OffSiteCarouselContent";

import { Colors, Configs, Strings } from "../../constants";

type ItemProps = {
  data: PlansResponseType;
  type: "credit_card" | "offsite";
  onClick: (id: string) => void;
};

const Item = ({ data, type, onClick }: ItemProps) => {
  const isCreditCardType = type === "credit_card";

  const handleClick = () => {
    onClick(data.id);
  };

  return isCreditCardType ? (
    <CreditCardCarouselContent data={data} onClick={handleClick} />
  ) : (
    <OffSiteCarouselContent data={data} onClick={handleClick} />
  );
};

type HOCCarouselProps = {
  data?: PlansResponseType[];
  type: "credit_card" | "offsite";
  onClick: (id: string) => void;
};

const HOCCarousel: React.FC<HOCCarouselProps> = ({ data, type, onClick }) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);
  const plans = data?.filter((item) => !item.custom);
  const customPlans = data?.filter((item) => item.custom);

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems={isMobile ? "flex-start" : "center"}
      gap={isMobile ? "33px" : "58px"}
    >
      <Wrapper display="flex" padding="16px 16px 16px 0">
        <Carousel
          withoutControls
          slidesToShow={isMobile ? 1 : 2}
          dragging={isMobile}
        >
          {plans?.map((plan) => (
            <Item key={plan.id} data={plan} type={type} onClick={onClick} />
          ))}
        </Carousel>
      </Wrapper>

      <Wrapper
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {customPlans?.map((plan) => (
          <Span
            color={Colors.DENIM}
            fontSize={isMobile ? "15px" : "24px"}
            fontWeight="400"
            textAlign="center"
          >
            Caso já tenha a condição comercial negociada
            <br /> com o PagBank,
            <Span
              color={Colors.DENIM}
              fontSize="inhered"
              fontWeight="400"
              textAlign="center"
              cursor="pointer"
              onClick={() => onClick(plan.id)}
            >
              {Strings.CLICK_HERE}
            </Span>
          </Span>
        ))}
      </Wrapper>
    </Wrapper>
  );
};

export default HOCCarousel;
