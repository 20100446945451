const KeyValue = {
  CONTENT_TYPE_KEY: "Content-Type",
  CONTENT_TYPE_VALUE: "application/json",
  X_ONBOARDING_TOKEN_KEY: "X-Onboarding-Token",
  X_ONBOARDING_SESSION_ID_KEY: "X-Onboarding-Session-Id",
  SESSION_ID_STORAGE_KEY: "session_id",
  MOCK_MODE_KEY: "Mock-mode",
  TYPE: "type",
};

const Configs = {
  MOBILE_SIZE: "(min-width: 767px)",
};

const Strings = {
  NEXT: "NEXT",
  PREV: "PREV",
  COPY_RIGHTS: "© 1996-2019 O melhor conteúdo. Todos os direitos reservados.",
  COPY_RIGHTS_MOBILE: "© 1996-2022 Todos os direitos reservados.",
  CREDIT_CARD: "Cartão de crédito",
  NO_INTERNET_CONNECTION: "Verifique sua internet.",
  SOMETHING_WENT_WRONG: "Alguma coisa deu errado.",
  GO_BACK_TO_SHOPIFY: "Voltar para o Shopify",
  LOADING: "Carregando ...",
  YOUR_PAGBANK_CONNECTOR: "Seu conector do PagBank",
  HAS_BEEN_CONFIGURED_SUCCESSFULLY: "foi configurado com sucesso!",
  YOU_CAN_NOW_START_SELLING_WITH_PAGBANK:
    "Você já pode começar a vender com o PagBank em sua loja Shopify.",
  CLICK_HERE: " clique aqui",
  SELECT_AN_OPTION: "Selecione uma opção",
  PAGE_NOT_FOUND: "Página não encontrada",
  TO_GO_BACK: "Voltar",
  CONTINUE: "Continuar",
  MAXIMUM_NUMBER_OF_INSTALLMENTS: "Quantidade máxima de parcelas",
  CHOOSE_THE_OPTIONS:
    "Escolha as opções que deseja disponibilizar para os seus clientes",
  PAYMENT_OPTIONS: "Opções de pagamento",
  COMMERCIAL_CONDITION: "Condição comercial",
  THESE_ARE_YOUR_ACTIVE_SETTINGS:
    "Estas são suas configurações ativas no PagBank. Você pode alterá-las selecionando outra opção",
};

const HttpStatusCodes = {
  OK: 200,
  MULTIPLE_CHOICES: 300,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

const Colors = {
  MOUNTAIN_MEADOW: "#1BB99A",
  DOVE_GREY: "#666666",
  WHITE: "#FFFFFF",
  MINE_SHAFT: "#292929",
  WILD_SAND: "#F6F6F6",
  BOULDER: "#767676",
  BLACK: "#000000",
  SILVER: "#CCCCCC",
  DUSTY_GRAY: "#999999",
  SHARK: "#272B2D",
  SHARKISH: "#1C1E20",
  CHENIN: "#D5E069",
  MERCURY: "#E2E2E2",
  DENIM: "#1082BE",
  SCORPION: "#595959",
  DARK_CHARCOAL: "#333333",
  CYAN_CORNFLOWER_BLUE: "#138FBB",
};

export { Colors, Configs, HttpStatusCodes, KeyValue, Strings };
