import { compact } from "lodash";

import * as ApiHelper from "./ApiHelper";
import * as StringHelper from "./StringHelper";

import { KeyValue } from "../constants";

export const brlFormatter = new Intl.NumberFormat("pt-Br", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const formatNumberToPercentage = (value: number) => {
  return Number(value / 10000).toLocaleString("pt-Br", {
    style: "percent",
    minimumFractionDigits: 2,
  });
};

export const formatNumberToCurrency = (value: number): string => {
  if (Number.isNaN(value / 100)) return "R$ 0,00";
  return brlFormatter.format(value / 100);
};

export const onlyNumbers = (value: string) => value.replace(/\D/g, "");

export const generateSessionId = () => {
  return Math.floor(Math.random() * 1000000000).toString();
};

export const isNullOrEmpty = (...args: any[]): boolean => {
  return compact(args).length === 0;
};

export const saveSessionId = (value: string) => {
  window.sessionStorage.setItem(KeyValue.SESSION_ID_STORAGE_KEY, value);
};

export const getSessionId = () => {
  const stringValue = window.sessionStorage.getItem(
    KeyValue.SESSION_ID_STORAGE_KEY
  );

  if (!isNullOrEmpty(stringValue)) {
    return stringValue!;
  }

  const newSessionId = generateSessionId();

  saveSessionId(newSessionId);

  return newSessionId;
};

export { ApiHelper, StringHelper };
