import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { StringHelper } from ".";

import { ErrorResponseType } from "../types";

import { HttpStatusCodes, KeyValue, Strings } from "../constants";

import env from "../env.json";
const { baseURL } = env;

export const handleAxiosError = (error: AxiosError) => {
  const data = error?.response?.data as ErrorResponseType;

  if (error.response) {
    switch (data.responseCode) {
      case HttpStatusCodes.NOT_FOUND:
      case HttpStatusCodes.REQUEST_TIMEOUT:
      case HttpStatusCodes.INTERNAL_SERVER_ERROR:
      case HttpStatusCodes.NOT_IMPLEMENTED:
      case HttpStatusCodes.BAD_GATEWAY:
      case HttpStatusCodes.SERVICE_UNAVAILABLE:
      case HttpStatusCodes.GATEWAY_TIMEOUT:
        return {
          statusCode: data?.responseCode,
          message: Strings.SOMETHING_WENT_WRONG,
        };
      default:
        return {
          statusCode: data?.responseCode,
          message: data.cause?.errors[0]?.message,
        };
    }
  } else if (error.request) {
    return {
      statusCode: HttpStatusCodes.BAD_REQUEST,
      message: Strings.NO_INTERNET_CONNECTION,
    };
  } else {
    return {
      statusCode: data?.responseCode,
      message: data.cause?.errors[0]?.message,
    };
  }
};

export const interceptorRequest = (request: InternalAxiosRequestConfig) => {
  const data = request.data;
  const method = request.method;
  const url = baseURL + request.url;

  console.log(`-> [${method}] - ${url}`);
  data && console.log(`BODY: ${JSON.stringify(data, null, 2)}`);

  const token = StringHelper.getKeyValue(KeyValue.X_ONBOARDING_TOKEN_KEY);
  console.error("✅", token);

  if (StringHelper.isNotNullOrEmpty(token)) {
    request.headers[KeyValue.X_ONBOARDING_TOKEN_KEY] = token;
  }

  return request;
};

export const interceptorResponse = (response: AxiosResponse) => {
  const data = response.data;

  const method = response.config.method;
  const status = response.status;
  const url = baseURL + response.config.url;

  console.log(`<- [${method}] - ${url} - [${status}]`);
  data && console.log(`BODY: ${JSON.stringify(data, null, 2)}`);

  return response;
};
