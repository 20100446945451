import { api } from "../api";

import {
  AppConfigurationType,
  ConfigurationType,
  PlanResponseType,
  PlansResponseType,
} from "../types";

export const getPlans = async (type: string) => {
  const url = `/pagbank-plans?type=${type}`;
  const { data } = await api.get<PlansResponseType[]>(url);
  return data;
};

export const getPlan = async (planId: string) => {
  const url = `/connect-url/plan/${planId}`;
  const { data } = await api.get<PlanResponseType>(url);
  return data;
};

export const getConfiguration = async () => {
  const url = "/configuration";
  const { data } = await api.get<ConfigurationType>(url);
  return data;
};

export const changeConfiguration = async (body: ConfigurationType) => {
  const url = `/configuration`;
  const { data } = await api.patch<ConfigurationType>(url, body);
  return data;
};

export const getAppConfigurationURL = async (token: string) => {
  const url = "/app-configuration-url";
  const { data } = await api.get<AppConfigurationType>(url);
  return data;
};
