import React from "react";

import { useMediaQuery } from "../../hooks";

import { formatNumberToCurrency, formatNumberToPercentage } from "../../utils";

import { PlansResponseType } from "../../types";

import Button from "../shared/Button";
import Image from "../shared/Image";
import Span from "../shared/Span";
import Wrapper from "../shared/Wrapper";

import { PixSVG, SemCustoSVG } from "../../assets/images";

import { Colors, Configs } from "../../constants";

type OffSiteCarouselContentProps = {
  data: PlansResponseType;
  onClick: (id: string) => void;
};

const OffSiteCarouselContent: React.FC<OffSiteCarouselContentProps> = ({
  data,
  onClick,
}) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);

  const boleto_tax_value = data?.boleto_tax_value ?? 0;
  const pix_tax_percentage = data?.pix_tax_percentage ?? 0;

  const boletoTaxValue = formatNumberToCurrency(boleto_tax_value);
  const pixTaxPercentage = formatNumberToPercentage(pix_tax_percentage);

  const handleClick = () => {
    onClick(data.id);
  };

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      alignItems="center"
      minWidth={isMobile ? "360px" : "480px"}
      borderRadius="10px"
      backgroundColor={Colors.WHITE}
      boxShadow="0px 12px 54px rgba(0,0,0,0.13)"
      margin="16px 16px"
    >
      <Wrapper
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="65px"
        borderRadius="10px 10px 0px 0px"
        backgroundColor={Colors.MOUNTAIN_MEADOW}
        width="100%"
      >
        <Span
          color={Colors.WHITE}
          fontSize={isMobile ? "14px" : "20px"}
          fontWeight="700"
        >
          Receba com PagBank
        </Span>
      </Wrapper>
      <Wrapper
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={isMobile ? "16px" : undefined}
      >
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="48px"
          width="100%"
          gap="16px"
        >
          <Wrapper display="flex" alignItems="center" gap="8px">
            <Image src={SemCustoSVG} width="48px" height="48px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              Sem mensalidade e taxa de adesão
            </Span>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Image src={PixSVG} margin="0px 8px 0px 0px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              {`${pixTaxPercentage} por transação no Pix (Receba na Hora)`}
            </Span>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Image src={PixSVG} margin="0px 8px 0px 0px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              {`${boletoTaxValue} por Boleto (Receba em 2 dias)`}
            </Span>
          </Wrapper>
        </Wrapper>
        <Wrapper
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="0 0 33px 0"
        >
          <Button
            id="choose_plan"
            color={Colors.WHITE}
            fontSize={isMobile ? "12px" : "18px"}
            fontWeight="300"
            onClick={handleClick}
          >
            Habilitar Métodos
          </Button>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default OffSiteCarouselContent;
