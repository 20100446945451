export const getKeyValue = (key: string) => {
  const stringValue = window.sessionStorage.getItem(key);

  if (isNotNullOrEmpty(stringValue)) {
    return stringValue;
  }
  removeKeyValue(key);
  return null;
};

export const saveKeyValue = (key: string, value: string) => {
  window.sessionStorage.setItem(key, value);
};

export const removeKeyValue = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const isNullOrEmpty = (value: any) => {
  if (typeof value === "string") {
    return value.trim().length === 0;
  }

  return value === undefined;
};

export const isNotNullOrEmpty = (value: any) => {
  if (typeof value === "string") {
    return value.trim().length !== 0 || value !== undefined;
  }

  return value !== null;
};
