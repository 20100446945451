import React from "react";

import { useMediaQuery } from "../../hooks";

import Card from "../shared/Card";

import {
  Cartao1X1SVG,
  CertificadoSVG,
  MoneySVG,
  SegurancaSVG,
} from "../../assets/images";

import { Configs } from "../../constants";

const OffSiteAdvantagesType: React.FC = () => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);

  return (
    <>
      <Card
        isMobile={isMobile}
        title="Segurança para seus clientes"
        subTitle="Suporte 24h"
        icon={SegurancaSVG}
      />
      <Card
        isMobile={isMobile}
        title="ATIVE CARTÃO DE CRÉDITO EM 12X"
        subTitle="Mais metódos de pagamento com a mesma segurança"
        icon={Cartao1X1SVG}
      />
      <Card
        isMobile={isMobile}
        title="CONFIANÇA  E CREDIBILIDADE"
        subTitle="Somos uma das principais empresas de pagamentos"
        icon={CertificadoSVG}
      />
      <Card
        isMobile={isMobile}
        title="EXPERIÊNCIA SIMPLIFICADA"
        subTitle="Integração ágil e solução intuitiva para clientes"
        icon={MoneySVG}
      />
    </>
  );
};

export default OffSiteAdvantagesType;
